import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IEmpresa } from "@shared/models/empresa";
import { environment } from "environments/environment";
import { BehaviorSubject, firstValueFrom, map, Observable, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class EmpresaService {
  datosEmpresa: IEmpresa;
  inProcess = false;
  private _empresa: BehaviorSubject<IEmpresa> = new BehaviorSubject<IEmpresa>({
    idempresa: 0,
    razon_social: '',
    idcondicioniva: 0,
    logo: null,
    color_primario: '',
    color_secundario: '',
    articulo_insumo: 0,
    articulo_producto: 0,
    articulo_servicio: 0,
    cliente_asociado: 0,
    favicon: null,
    deposito_default: null
  })

  constructor(private _httpClient: HttpClient) {
    this._empresa.next({
      idempresa: 0,
      razon_social: '',
      idcondicioniva: 0,
      logo: null,
      color_primario: '',
      color_secundario: '',
      articulo_insumo: 0,
      articulo_producto: 0,
      articulo_servicio: 0,
      cliente_asociado: 0,
      favicon: null,
      deposito_default: null
    })
  }

  get empresa$(): Observable<IEmpresa> {
    return this._empresa.asObservable();
  }

  set _empresaData(empresaData) {
    this.datosEmpresa = empresaData;
    const logoUrl = empresaData.logo ? environment.empresa.uploads + empresaData.logo : null;
    const faviconUrl = empresaData.favicon ? environment.empresa.uploads + empresaData.favicon : null;

    this._empresa.next({
      idempresa: empresaData.idempresa,
      razon_social: empresaData.razon_social,
      idcondicioniva: empresaData.idcondicioniva,
      logo: logoUrl,
      color_primario: empresaData.color_primario,
      color_secundario: empresaData.color_secundario,
      articulo_insumo: empresaData.articulo_insumo,
      articulo_producto: empresaData.articulo_producto,
      articulo_servicio: empresaData.articulo_servicio,
      cliente_asociado: empresaData.cliente_asociado,
      favicon: faviconUrl,
      deposito_default: empresaData.deposito_default,
    });
  }

  get _empresaData() {
    return this.datosEmpresa;
  }

  parsearTiposArticulo(data: IEmpresa): string[] {
    let tiposPermitidos: string[] = [];

    if (data.articulo_insumo === 1) {
      tiposPermitidos.push('insumo');
    }
    if (data.articulo_producto === 1) {
      tiposPermitidos.push('producto');
    }
    if (data.articulo_servicio === 1) {
      tiposPermitidos.push('servicio');
    }

    return tiposPermitidos;
  }

  parsearTipoCliente(data: IEmpresa): string[] {
    let tipoCliente: string[] = [];

    if (data.cliente_asociado === 1) {
      tipoCliente.push('cliente_asociado');
    }

    return tipoCliente;
  }

  tieneClientesAsociados(): Observable<boolean> {
    return this.empresa$.pipe(
      map(empresa => empresa.cliente_asociado === 1)
    );
  }

  parsearDatosEmpresa(empresa: IEmpresa) {
    return {
      idempresa: empresa.idempresa,
      razon_social: empresa.razon_social,
      idcondicioniva: empresa.idcondicioniva,
      logo: empresa.logo,
      color_primario: empresa.color_primario,
      color_secundario: empresa.color_secundario,
      articulo_insumo: empresa.articulo_insumo,
      articulo_producto: empresa.articulo_producto,
      articulo_servicio: empresa.articulo_servicio,
      cliente_asociado: empresa.cliente_asociado,
      favicon: empresa.favicon,
      deposito_default: empresa.deposito_default,
    };
  }

  async getEmpresaX(): Promise<boolean> {
    this.inProcess = true;

    const res = await firstValueFrom(this._httpClient.post<IEmpresa>(`${environment.empresa.api}`, null));

    this._empresaData = this.parsearDatosEmpresa(res['empresa']);

    return true;
  }

  getEmpresa() {
    if (!this.inProcess) {
      this.getEmpresaX().then(res => {
        this.inProcess = res;
      })
    }
  }

  get(): Observable<IEmpresa> {
    const empresaData = {
      idempresa: 0,
      razon_social: '',
      idcondicioniva: 0,
      logo: null,
      color_primario: '',
      color_secundario: '',
      articulo_insumo: 0,
      articulo_producto: 0,
      articulo_servicio: 0,
      cliente_asociado: 0,
      favicon: null,
      deposito_default: null
    }

    return of(empresaData);
  }
}