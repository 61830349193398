import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { SafeUrlPipe } from "@shared/pipes/safe-url.pipe";
import { JedsMenuService } from "@shared/service/app/jeds-menu.service";
import { PwaService } from "@shared/service/app/pwa.service";
import { LectorQrComponent } from "./lector-qr/lector-qr.component";

@NgModule({
    declarations: [SafeUrlPipe, LectorQrComponent],
    imports: [
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        CommonModule,
        OverlayModule,
        PortalModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatDividerModule,
        MatAutocompleteModule,
        RouterModule,
        ReactiveFormsModule,
        // FuseDrawerModule,
        // FuseScrollbarModule,
        // FuseLoadingBarModule,
        // FuseFullscreenModule,
        // FuseNavigationModule,
        // FuseAlertModule
    ],
    exports: [SafeUrlPipe],
    providers: [ JedsMenuService, PwaService ],
})
export class SharedComponentModule { }