import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Html5Qrcode } from 'html5-qrcode';

@Component({
  selector: 'app-lector-qr',
  templateUrl: './lector-qr.component.html',
  styleUrls: ['./lector-qr.component.scss']
})
export class LectorQrComponent implements OnInit, OnDestroy {
  @Output() sendObject = new EventEmitter<any>();
  scanner: Html5Qrcode;

  constructor() { }
  ngOnDestroy(): void {
    this.stopScanner();
  }

  ngOnInit(): void {
    this.initScanner();
  }

  initScanner() {
    Html5Qrcode.getCameras().then(devices => {
      if (devices && devices.length) {
        const cameraId = devices[devices.length - 1].id; 
        this.scanner = new Html5Qrcode('reader');
        this.scanner.start(
          cameraId,
          {
            fps: 30,
            qrbox: 250 // Ajusta el tamaño del cuadro de escaneo según sea necesario
          },
          (decodedText, decodedResult) => {
            let code = decodedText.split(':')[1].replace('"', '').replace('"', '').replace('}', '');
            this.stopScanner()
            this.sendObject.emit(code);
          },
          (error) => {
            console.log(error);
          }
        ).catch(err => {
          console.log(err);
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }
  stopScanner() {
    if (this.scanner) {
      if (this.scanner.getState() == 2) {
        this.scanner.stop().then(() => {
          console.log("Escáner detenido.");
          const overlayBackdrop = document.querySelector('.cdk-overlay-backdrop');
          if (overlayBackdrop) {
            // Verifica si hay más de un overlay (es decir, si se está ejecutando dentro de un modal)
            const overlays = document.querySelectorAll('.cdk-overlay-backdrop');
            if (overlays.length > 1) {
              // Elimina el overlay del scanner (el último que se agregó)
              overlays[overlays.length - 1].remove();
            } else {
              // Si solo hay un overlay, elimina ese
              overlayBackdrop.remove();
            }
          }
          const readerElement = document.getElementById('reader');
          if (readerElement) {
            readerElement.remove(); // Elimina el elemento del DOM
          }
        }).catch(err => {
          console.error("Error al detener el escáner: ", err);
        });
      }
    }
  }
}
