import { Component, ComponentFactoryResolver, OnInit, ViewContainerRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EmpresaService } from '@shared/service/app/empresa.service';
import { TokenService } from '@shared/service/app/token.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';
import { CustomSnackbarComponent } from '@shared/components/custom-snackbar/custom.component';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent implements OnInit {
    nombre_empresa: string;
    favicon: string;
    private originalTitle: string = 'Sistema';
    private defaultFavicon: string = './favicon-32x32.png';
    private snackBarDisplayed = false; 

    /**
     * Constructor
     */
    constructor(
        private titulo: Title,
        private _empresaService: EmpresaService,
        private swUpdate: SwUpdate,
        private resolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
        // private _tokenService: TokenService
    ) {}

    ngOnInit(): void {
        // Obtén los datos de la empresa al inicializar el componente
        this.obtenerDatosEmpresa();
        this.swUpdate.versionUpdates
            .pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'))
            .subscribe(() => {
                if (!this.snackBarDisplayed) {
                    this.showCustomSnackbar('Nueva Versión disponible');
                    this.snackBarDisplayed = true; 
                }
            });
    }

    obtenerDatosEmpresa(): void {
        // Utiliza el servicio EmpresaService para obtener los datos de la empresa
        this._empresaService.empresa$.subscribe(empresaData => {
            if (empresaData) {
                // Asigna el nombre de la empresa para mostrarlo en el título
                this.nombre_empresa = empresaData.razon_social;

                // Establece el título del documento HTML con el nombre de la empresa
                const tituloEmpresa = `${this.nombre_empresa} ${this.originalTitle}`;
                this.titulo.setTitle(tituloEmpresa);

                // Guarda el favicon de la empresa
                this.favicon = empresaData.favicon || this.defaultFavicon;

                // Establece el favicon en el documento HTML
                this.setFavicon(this.favicon);
            }
        });
    }

    // Método para establecer el favicon en el documento HTML
    setFavicon(url: string): void {
        const link: HTMLLinkElement = document.querySelector("link[rel~='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = url;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    // Método para restablecer el favicon y el título original al cerrar sesión
    resetFaviconAndTitle(): void {
        // Restablecer el título original del documento HTML
        this.titulo.setTitle(this.originalTitle);

        // Restablecer el favicon por defecto
        this.setFavicon(this.defaultFavicon);
    }

    showCustomSnackbar(message: string): void {
        this.viewContainerRef.clear();

        const factory = this.resolver.resolveComponentFactory(CustomSnackbarComponent);
        const componentRef = this.viewContainerRef.createComponent(factory);

        componentRef.instance.message = message;

        componentRef.instance.reload.subscribe(() => {
            this.swUpdate.activateUpdate().then(() => {
                window.location.reload();
            });
        });
    }
}